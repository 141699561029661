import {
  AutocompleteArrayInput,
  AutocompleteInput,
  BooleanInput,
  NumberInput,
  ReferenceArrayInput,
  ReferenceInput,
  SelectArrayInput,
  SelectInput,
  TextInput
} from "react-admin"
import React from "react"

export const InputFilter = (el: any) => {

  if (el?.type?.settings?.type === "select") {
    if (el?.type?.settings?.multiple) {
      return (<SelectArrayInput
        label={el.name}
        source={`properties.${el.symbolCode}.value`}
        choices={el.type.valueVariants}
        optionText="value"
        optionValue="id"
      />)
    }
    return (<SelectInput
      label={el.name}
      source={`properties.${el.symbolCode}.value`}
      choices={el.type.valueVariants}
      optionText="value"
      optionValue="id"
    />)
  } else if (el?.type?.settings?.type === "boolean") {
    return (<BooleanInput
      label={el.name}
      source={`properties.${el.symbolCode}.value`}
      defaultValue={true}
    />)
  } else if (el?.type?.settings?.type === "number") {
    return (<NumberInput
      label={`${el.name}`}
      source={`properties.${el.symbolCode}.value`}
    />)
  } else {
    return (<TextInput
      label={`${el.name}`}
      source={`properties.${el.symbolCode}.value`}
    />)
  }
}

export const RelationInputFilter = (el: any) => {

  const label = templatesName.find(template => template.symbolCode === el.template.symbolCode)?.name
  console.log(label)
  const filterToQuery = searchText => ({
    properties: {
      name: {
        value: searchText
      }
    }
  })

  const optionText = record => {
    if (record.properties.name) {
      return `${record.id}_${record.properties.name.value}`
    } else if (record.properties.position) {
      return `${record.id}_${record.properties.position.value}`
    } else if (record.properties.title) {
      return `${record.id}_${record.properties.title.value}`
    } else return `${record.id}`
  }

  if (el.typeId === 2 || el.typeId === 1) {
    return (<ReferenceInput
      key={`${el.id}-${el.template.symbolCode}`}
      label={label}
      source={`relations.${el.template.symbolCode}.id`}
      reference={`1:${el.template.symbolCode}`}
    >
      <AutocompleteInput
        label={label}
        optionText={record => optionText(record)}
        filterToQuery={filterToQuery}
      />
    </ReferenceInput>)
  } else if (el.typeId === 3) {
    return (<ReferenceArrayInput
      key={`${el.id}-${el.template.symbolCode}`}
      label={label}
      source={`relations.${el.template.symbolCode}.id`}
      reference={`1:${el.template.symbolCode}`}
    >
      <AutocompleteArrayInput
        label={label}
        optionText={record => optionText(record)}
        filterToQuery={filterToQuery}
      />
    </ReferenceArrayInput>)
  }
}

export const templatesName = [
  {name: "Отзыв", symbolCode: "review"},
  {name: "Тренажерный зал", symbolCode: "gym"},
  {name: "Тренажерный зал тренера", symbolCode: "trainerGym"},
  {name: "Тренировка", symbolCode: "training"},
  {name: "Стрим", symbolCode: "stream"},
  {name: "Программа питания", symbolCode: "nutritionProgram"},
  {name: "День программы питания", symbolCode: "nutritionProgramDay"},
  {name: "Прием пищи дня программы питания", symbolCode: "nutritionProgramDayMeal"},
  {name: "Программа питания клиента", symbolCode: "clientNutritionProgram"},
  {name: "День программы питания клиента", symbolCode: "clientNutritionProgramDay"},
  {name: "Прием пищи дня программы питания клиента", symbolCode: "clientNutritionProgramDayMeal"},
  {name: "Блюдо", symbolCode: "dish"},
  {name: "Блюдо приема пищи дня программы питания", symbolCode: "nutritionProgramDayMealDish"},
  {name: "Блюдо приема пищи дня программы питания клиента", symbolCode: "clientNutritionProgramDayMealDish"},
  {name: "Продукт", symbolCode: "product"},
  {name: "Продукт блюда", symbolCode: "dishProduct"},
  {name: "Продукт блюда приема пищи дня программы питания", symbolCode: "nutritionProgramDayMealDishProduct"},
  {name: "Продукт блюда приема пищи дня программы питания клиента", symbolCode: "clientNutritionProgramDayMealDishProduct" },
  {name: "Программа тренировок", symbolCode: "trainingProgram"},
  {name: "День программы тренировок", symbolCode: "trainingProgramDay"},
  {name: "Упражнение", symbolCode: "exercise"},
  {name: "Группы мышц упражнений", symbolCode: "exerciseGroup"},
  {name: "Упражнение дня программы тренировок", symbolCode: "trainingProgramDayExercise"},
  {name: "Подход", symbolCode: "set"},
  {name: "Измерение", symbolCode: "measurement"},
  {name: "Программа тренировок клиента", symbolCode: "clientTrainingProgram"},
  {name: "День программы тренировок клиента", symbolCode: "clientTrainingProgramDay"},
  {name: "Упражнение дня программы тренировок клиента", symbolCode: "clientTrainingProgramDayExercise"},
  {name: "День программы тренировок в тренировке", symbolCode: "trainingProgramDayInTraining"},
  {name: "Упражнение дня программы тренировок в тренировке", symbolCode: "trainingProgramDayInTrainingExercise"},
  {name: "План тренировок", symbolCode: "trainingPlan"},
  {name: "План программ тренировок", symbolCode: "trainingProgramPlan"},
  {name: "Сообщество", symbolCode: "post"},
  {name: "Реакции", symbolCode: "reaction"},
  {name: "Опрос", symbolCode: "survey"},
  {name: "Вопрос опроса", symbolCode: "surveyQuestion"},
  {name: "Ответ на вопрос опроса", symbolCode: "surveyAnswer"},
  {name: "Пачка ответов на вопрос опроса", symbolCode: "surveyAnswerPack"},
  {name: "Цель программы тренировок", symbolCode: "trainingProgramGoal"},
  {name: "Место проведения тренировок", symbolCode: "trainingProgramPlace"},
  {name: "Уровень программы тренировок", symbolCode: "trainingProgramTrainingLevel"},
]

export const excludeFilter = ["images", "image", "previewImage", "animation", "video", "description", "likesInfo", "links", "latitude", "longitude", "schedule", "reviewsInfo", "technique", "weight", "sets", "repetitions", "duration", "distance", "steps", "angle", "intensity", "speed", "minAge", "maxAge", "document", "initialPrice", "price", "sort", "agoraTokenForResponder", "agoraTokenForCaller", "agoraToken", "recipe", "calories", "proteins", "fats", "carbohydrates", "edited"]

//title нужен только для отзывов
//есть поля которые должны быть при определенных сущностях