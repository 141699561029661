import {
  ArrayField,
  Datagrid, FunctionField,
  ReferenceManyField,
  TextField,
  TabbedShowLayout, DateField
} from "react-admin"
import StarIcon from '@mui/icons-material/Star'
import ThumbUpAltIcon from '@mui/icons-material/ThumbUpAlt'
import ThumbDownAltIcon from '@mui/icons-material/ThumbDownAlt'
import React from "react"

enum Label_List_SymbolCode {
    'nutritionProgram' = 'Программа питания',
    'nutritionProgramDay' = 'Дни программы питания',
    'nutritionProgramDayMeal' = 'Прием пищи дня программы питания',
    'nutritionProgramDayMealDish' = 'Блюдо приема пищи дня программы питания',
    'dish' = 'Блюдо',
    'dishProduct' = 'Продукты блюда',
    'product' = 'Продукты',
    'trainingProgram' = 'Программа тренировок',
    'trainingProgramDay' = 'Дни программы тренировок',
    'trainingProgramDayExercise' = 'Упражнения дня программы тренировок',
    'training' = 'Тренировки',
    'review' = 'Отзывы',
    'reaction' = 'Реакции',
    'exercise' = 'Упражнения',
    'exerciseGroup' = 'Группы мышц',
    'trainingPlan' = 'trainingPlan',
    'trainingProgramPlan' = 'trainingProgramPlan',
    'gym' = 'Тренажерный зал',
    'trainerGym' = 'Тренажерный зал тренера',
    'project' = 'Проект',
    'projectComponent' = 'Состовные части проекта',
    'projectComponentFormattedText' = 'Текст',
    'projectComponentQuote' = 'Отзыв',
    'projectComponentSlider' = 'Слайдер',
    'projectComponentSliderSlide' = 'Состовные части слайдера',
    'projectComponentVideo' = 'Видео',
    'survey' = 'Опрос',
    'surveyQuestion' = 'Вопросы опроса',
    'surveyAnswer' = 'Ответы',
    'trainingProgramGoal' = 'Цель',
    'trainingProgramPlace' = 'Место тренировки',
    'trainingProgramTrainingLevel' = 'Уровень подготовки'
}

export const CustomTab = (relatedEntity: any, resource:unknown) => {
  const symbolCode: string = relatedEntity?.template?.symbolCode ?? ''
  const [applicationId, entityName] = resource.split(':')
  if (symbolCode === "review") {
    return (
      //TODO погуглить как задать горизонтальный скролл, так как связей с сущностью может быть много
      <TabbedShowLayout.Tab
        style={{ overflow: 'scroll'}}
        label={Label_List_SymbolCode[symbolCode as keyof typeof Label_List_SymbolCode]}
      >
        <ArrayField label={false} source={`relations.${symbolCode}`}>
          <ReferenceManyField
            reference={`${applicationId}:${symbolCode}`}
            source={`relations.${symbolCode}`}
            target={"id"}
          >
            <Datagrid rowClick={"show"} bulkActionButtons={false}>
              <TextField label={"ID"} source={"id"} />
              <DateField label="Дата создания" source="createdAt" readOnly />
              <DateField label="Дата обновления" source="updatedAt" readOnly />
              <TextField label={"Заголовок"} source={"properties.title.value"} />
              <FunctionField
                label={"Оценка"}
                render={(record: any) => (
                  <div style={{ display: "flex"}}>
                    {([...Array(Number(record?.properties?.grade?.value))] ?? []).map(el => <StarIcon />)}
                  </div>
                )}
              />
              <TextField label={"Отзыв"} source={`properties.review.value`} multiline={true}/>
            </Datagrid>
          </ReferenceManyField>
        </ArrayField>
      </TabbedShowLayout.Tab>
    )
  } else   if (symbolCode === "reaction") {
    return (
      <TabbedShowLayout.Tab label={Label_List_SymbolCode[symbolCode as keyof typeof Label_List_SymbolCode]}>
        <ArrayField label={false} source={`relations.${symbolCode}`}>
          <ReferenceManyField
            reference={`${applicationId}:${symbolCode}`}
            source={`relations.${symbolCode}`}
            target={"id"}
          >
            <Datagrid rowClick={"show"} bulkActionButtons={false}>
              <TextField label={"ID"} source={"id"} />
              <DateField label="Дата создания" source="createdAt" readOnly />
              <DateField label="Дата обновления" source="updatedAt" readOnly />
              <FunctionField
                label={"Реакция"}
                render={(record: any) => (
                  !!Number(record.properties.reactionType.value) ? <ThumbUpAltIcon /> : <ThumbDownAltIcon />
                )}
              />
            </Datagrid>
          </ReferenceManyField>
        </ArrayField>
      </TabbedShowLayout.Tab>
    )
  } else if(relatedEntity?.typeId === 1) {
    return (
      <TabbedShowLayout.Tab label={Label_List_SymbolCode[symbolCode as keyof typeof Label_List_SymbolCode]}>
        <ArrayField label={false} source={`relations.${symbolCode}`}>
          <ReferenceManyField
            reference={`${applicationId}:${symbolCode}`}
            source={`relations.${symbolCode}`}
            target={"id"}
          >
            <Datagrid rowClick={symbolCode === "nutritionProgramDayMealDish" ? false : "show"} bulkActionButtons={false}>
              <TextField label={"ID"} source={"id"} />
              <DateField label="Дата создания" source="createdAt" readOnly />
              <DateField label="Дата обновления" source="updatedAt" readOnly />
              <TextField label={"Название"} source={"properties.name.value"} />
            </Datagrid>
          </ReferenceManyField>
        </ArrayField>
      </TabbedShowLayout.Tab>
    )
  } else if(relatedEntity?.typeId === 2) {
    return (
      <TabbedShowLayout.Tab label={Label_List_SymbolCode[symbolCode as keyof typeof Label_List_SymbolCode]}>
        <ArrayField label={false} source={`relations.${symbolCode}`}>
          <ReferenceManyField
            reference={`${applicationId}:${symbolCode}`}
            source={`relations.${symbolCode}`}
            target={"id"}
          >
            <Datagrid rowClick={"show"} bulkActionButtons={false}>
              <TextField label={"ID"} source={"id"} />
              <DateField label="Дата создания" source="createdAt" readOnly />
              <DateField label="Дата обновления" source="updatedAt" readOnly />
              {
                symbolCode.endsWith('Question')
                  ? <TextField label={'Текст'} source="properties.text.value" />
                  : null
              }
              {
                !symbolCode.endsWith('Day')
                && !symbolCode.endsWith('Question')
                && !symbolCode.startsWith('survey')
                  ? <TextField label={'Название'} source="properties.name.value" />
                  : null
              }
              {
                symbolCode.endsWith('Day')
                  ? <TextField label={'Позиция'} source="properties.position.value" />
                  : null
              }
              {
                symbolCode === 'surveyAnswer'
                  ? <TextField label={'Ответ'} source="properties.answer.value" />
                  : null
              }
              {
                symbolCode === 'surveyAnswer'
                  ? <TextField label={'Комментарий'} source="properties.comment.value" />
                  : null
              }
            </Datagrid>
          </ReferenceManyField>
        </ArrayField>
      </TabbedShowLayout.Tab>
    )
  } else if(relatedEntity?.typeId === 3) {
    return (
      <TabbedShowLayout.Tab label={Label_List_SymbolCode[symbolCode as keyof typeof Label_List_SymbolCode]}>
        <ArrayField label={false} source={`relations.${symbolCode}`}>
          <ReferenceManyField
            reference={`${applicationId}:${symbolCode}`}
            source={`relations.${symbolCode}`}
            target={"id"}
          >
            <Datagrid rowClick={"show"} bulkActionButtons={false}>
              <TextField label={"ID"} source={"id"} />
              <DateField label="Дата создания" source="createdAt" readOnly />
              <DateField label="Дата обновления" source="updatedAt" readOnly />
              {
                symbolCode.endsWith('Question')
                  ? <TextField label={'Текст'} source="properties.text.value" />
                  : null
              }
              {
                !symbolCode.endsWith('Day')
                && !symbolCode.endsWith('Question')
                && !symbolCode.startsWith('survey')
                  ? <TextField label={'Название'} source="properties.name.value" />
                  : null
              }
              {
                symbolCode.endsWith('Day')
                  ? <TextField label={'Позиция'} source="properties.position.value" />
                  : null
              }
              {
                symbolCode === 'survey'
                  ? <TextField label={'Заголовок'} source="properties.title.value" />
                  : null
              }
            </Datagrid>
          </ReferenceManyField>
        </ArrayField>
      </TabbedShowLayout.Tab>
    )
  } else if(relatedEntity?.typeId === 4) {
    return (
      <TabbedShowLayout.Tab label={Label_List_SymbolCode[symbolCode as keyof typeof Label_List_SymbolCode]}>
        <>BelongsMany</>
      </TabbedShowLayout.Tab>
    )
  }

  return
}
